<template>
    <div>
        <row ref="row" :base="$options.name"></row>
    </div>
</template>

<script>
    import row from '@/components/row.vue'

    export default {
        name: "duoKai",
        components:{
            row
        },
        data() {
            return {
                list:[
                    {
                        title: "多开说明",
                        text:[
                            '#多开针对win7系统,由于PC助手win7是后台操作,win10/win11是前台操作, 所以win7可以多开使用, win10/win11也可以多开,但是' +
                            '只能操作当前激活的游戏窗口,并且助手会占用鼠标键盘',
                            '#PC助手最多6开(九阴最多三开),基本原理是通过游戏窗口标题来绑定助手,一个PC助手对应一个游戏窗口',
                        ],
                        img:[]
                    },
                    {
                        title: "复制文件夹",
                        text:[
                            "#复制你要多开助手的文件夹,几开复制几个,保持一个助手文件夹对应一个游戏窗口,此操作是为了避免闪退"
                        ],
                        img:[
                            "1.png",
                        ]
                    },
                    {
                        title: "打开游戏,打开助手",
                        text:[
                            "#分别打开游戏,打开助手,助手对应游戏客户端数量(图2双开举例)"
                        ],
                        img:[
                            "2.png",
                        ]
                    },
                    {
                        title: "第一个窗口绑定",
                        text:[
                            "#打开第一个助手(两个助手哪个都行),点击设置,在绑定窗口选择游戏窗口(两个游戏哪个都行," +
                            "后面的数字是窗口的句柄ID,可以忽略),选择后点击绑定" +
                            "完成助手和游戏窗口的绑定关系(图123)",
                            "#完成后游戏窗口和助手如图4所示,都是窗口1"
                        ],
                        img:[
                            "3.png",
                            "4.png",
                            "5.png",
                            "6.png",
                        ]
                    },
                    {
                        title: "第二个窗口操作",
                        text:[
                            "#打开另一个助手,点击设置,在绑定窗口选择另一个游戏窗口(注意:不要绑到刚才的窗口),选择后点击绑定" +
                            "完成第二个助手和游戏窗口的绑定关系(图1)",
                            "#此时完成了双开,如图2所示",
                            "#点击开始任务后助手只会操作绑定窗口,注意快捷键,可以在设置选择快捷键方案(图3)"
                        ],
                        img:[
                            "7.png",
                            "8.png",
                            "9.png",
                        ]
                    },
                    {
                        title: "三开,四开",
                        text:[
                            '三开,四开到六开原理相同,绑定窗口即可'
                        ],
                        img:[]
                    },
                    {
                        title: "注意事项",
                        text:[
                            '#如果要恢复游戏标题,点击助手设置,点击"解绑并恢复窗口"(图12)',
                            '#多开必须绑定窗口,每一个都需要绑定,不绑定会控制到别的窗口',
                        ],
                        img:[
                            "11.png",
                            "10.png",
                        ]
                    },
                ],
            }
        },
        methods:{

        },
        mounted() {
            this.$refs.row.initData(this.list);
        }
    }
</script>

<style scoped>

</style>
